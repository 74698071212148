import React from 'react'
import { Link } from 'gatsby'
import { list, total } from 'cart-localstorage'
import { roundAndFix } from '../scripts/helpers'
import CartWidgetStyles from '../styles/CartWidget.module.sass'

const CartWidget = () => {
  let cart = typeof window !== 'undefined' ? list() : null
  let totalQuantity = calculateTotalQuantity()

  // Prevent Gatsby from prerendering this component
  if (typeof window == 'undefined') {
    return false
  }

  function calculateTotalQuantity(list) {
    if (list && list.length) {
      let initialQuantity = 0

      list.map(item => {
        initialQuantity = initialQuantity + item.quantity

        return false
      })

      return initialQuantity
    } else if (cart && cart.length) {
      let initialQuantity = 0

      cart.map(item => {
        initialQuantity = initialQuantity + item.quantity

        return false
      })

      return initialQuantity
    } else {
      return 0
    }
  }

  if (cart && cart.length) {
    return (
      <Link 
        className={`cart-widget ${CartWidgetStyles.cart || ''}`}
        to="/checkout/"
      >
        <header className={CartWidgetStyles.header || ''}>
          <div>
            <b>My Order</b> <i>&euro;{ roundAndFix(total()) }</i>
            <br />
            {(() => {
              if (totalQuantity === 1) {
                return (
                  <span>
                    1 product
                  </span>
                )
              } else if (totalQuantity > 1) {
                return (
                  <span>
                    {totalQuantity} products
                  </span>
                )
              }
            })()}
            <em>Checkout</em>
          </div>
        </header>
      </Link>
    )
  } else {
    return <div className={`cart-widget ${CartWidgetStyles.cartEmpty || ''}`}>Your cart is empty.</div>
  }
}

export default CartWidget

import React from 'react'
import { Link } from 'gatsby'
import FeaturedCategoriesStyles from '../../styles/FeaturedCategories.module.sass'

/**
 * Display Featured Categories
 *
 * @param {string} ignoreSlug A category slug to ignore
 */
const FeaturedCategories = props => {
  const ignoreSlug = props.ignoreSlug || false

  return (
    <div data-ignore-slug={ignoreSlug} className={`container is-semi-narrow featured-categories-holder ${FeaturedCategoriesStyles.holder || ''}`}>
      <div className="columns is-desktop is-centered">
        {
          ignoreSlug !== 'breast-pumps' && <Link to="/#products" className={FeaturedCategoriesStyles.category || ''}>
            <div className={FeaturedCategoriesStyles.categoryInner}>
              <b className={`title is-4 ${FeaturedCategoriesStyles.title || ''}`}>Breast Pumps</b>
              <span className={FeaturedCategoriesStyles.fauxButton || ''}>View Pumps</span>
            </div>
          </Link>
        }

        {
          ignoreSlug !== 'baby-bottles' && <Link to="/baby-bottles/" className={FeaturedCategoriesStyles.category || ''}>
            <div className={FeaturedCategoriesStyles.categoryInner}>
              <b className={`title is-4 ${FeaturedCategoriesStyles.title || ''}`}>Baby Bottles</b>
              <span className={FeaturedCategoriesStyles.fauxButton || ''}>View Bottles</span>
            </div>
          </Link>
        }

        {
          ignoreSlug !== 'sterilisers' && <Link to="/baby-bottle-sterilisers/" className={FeaturedCategoriesStyles.category || ''}>
            <div className={FeaturedCategoriesStyles.categoryInner}>
              <b className={`title is-4 ${FeaturedCategoriesStyles.title || ''}`}>Sterilisers</b>
              <span className={FeaturedCategoriesStyles.fauxButton || ''}>View Sterilisers</span>
            </div>
          </Link>
        }

        {
          (ignoreSlug.length && ignoreSlug !== 'extras') && <Link to="/mum-baby-care-products/" className={FeaturedCategoriesStyles.category || ''}>
            <div className={FeaturedCategoriesStyles.categoryInner}>
              <b className={`title is-4 ${FeaturedCategoriesStyles.title || ''}`}>Accessories &amp; Extras</b>
              <span className={FeaturedCategoriesStyles.fauxButton || ''}>View Products</span>
            </div>
          </Link>
        }
      </div>
    </div>
  )
}

export default FeaturedCategories
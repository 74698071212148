import React from 'react'
import { Link } from 'gatsby'
import Collapsible from 'react-collapsible'
import FaqSectionStyles from '../../styles/FaqSection.module.sass'

/**
 * Add an FAQ section
 *
 * @param {string}  [className]
 */
const FaqSection = props => {
  return (
    <div className={`${FaqSectionStyles.faqContainer || ''} ${props.className || ''}`}>
      <Collapsible trigger="Where in Ireland do you deliver and what are the costs?" open={true}>
        <p>We offer nationwide delivery in Ireland. However, please note that some exceptions may apply for remote areas or certain counties at certain times.</p>
        <ul>
          <li><b>Free Delivery</b> applies if your order contains any breast pump rental.</li>
          <li><b>€9 Delivery</b> applies on all other orders.</li>
        </ul>
      </Collapsible>

      <Collapsible trigger="Where do I return my rental pump?">
        <p>Our rental breast pumps need to be returned to the following address:</p>
        <p>1 Shackleton Green, Lucan, K78 R5F2.</p>
      </Collapsible>

      <Collapsible trigger="What happens if I do not return the pump in time?">
        <p>At the end of the initial hire period you must return the pump, otherwise renewal charges will be processed after 7 days and will be charged to your payment method automatically on a monthly basis until the pump is returned. See full details on our <Link to="/terms">Terms & Conditions</Link> page.</p>
      </Collapsible>

      <Collapsible trigger="What baby bottles are compatible with the Spectra S2 breast pump?">
        <p>Any wide neck botles such as the ones from Avent or MAM.</p>
      </Collapsible>

      <Collapsible trigger="Manual, Electric or Hospital-Grade Breast Pump?">
        <p>A manual breast pump is best for someone who only pumps occasionally. An electric pump is easier and more user friendly alternative, as the motor does all the work for you. A hospital grade pump is the most powerful option available on the market, engineered for adjustability, reliability, and with suction power that can be many steps above the others.</p>
      </Collapsible>

      <Collapsible trigger="Single or Double Pump?">
        <p>A double breast pump has been shown to draw on average 18% more milk as it alternates from breast to breast and provokes a higher hormonal response. Our hospital-grade pumps can be used in both configurations and come with all the necessary accessories and tubing.</p>
      </Collapsible>

      <Collapsible trigger="What is the best option if I want to build or increase my milk supply?">
        <p>Medical grade pumps are superior when it comes to initiating and building your milk supply, and they are excellent for someone who pumps frequently or exclusively. They regularly empty the breasts to stimulate and increase or maintain your milk production.</p>
      </Collapsible>

      <Collapsible trigger="Are there any downsides to hospital-grade breast pumps?">
        <p>The only negative is the purchase price, as they are essentially medical-grade equipment manufactured by a handful of companies and thoroughly researched and engineered for hospital use. Helping mothers avoid this cost is the reason why we offer rental of the top rated Hospital Pumps in Ireland.</p>
      </Collapsible>
    </div>
  )
}

export default FaqSection
